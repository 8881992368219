import { CheckCircleIcon, InfoIcon, WarningIcon } from '@chakra-ui/icons';
import {
  AlertIcon, Spinner, Tag, TagLabel,
} from '@chakra-ui/react';
import React from 'react';
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';
import { HealthIndicator, StatusColor, statusToColour } from '../../models/health';
import { StatusToIcon } from './StatusToIcon';

export interface HealthIndicatorTagProps {
  indicator: HealthIndicator
  name: string
}

export const HealthIndicatorTag: React.FC<HealthIndicatorTagProps> = ({ indicator, name }) => {
  if (!indicator) {
    return null;
  }

  const color = (indicator as any)[name] as StatusColor;

  return (
    <Tag
      size="lg"
      mb="2"
      colorScheme={
        statusToColour(color) || 'green'
      }
      borderRadius="full"
      ml="4"
    >
      <StatusToIcon status={color} />
      <TagLabel ml="2">{name || 'loading...'}</TagLabel>
    </Tag>
  );
};
