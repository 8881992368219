import * as ipaddr from 'ipaddr.js';
import CIDR from 'ip-cidr';

export const neptuneRanges = ['103.252.194.0/23', '2401:2520::/32'];

export const isNeptuneIp = (clientIp: string): boolean => {
  // Check if it's a valid IP address (IPv4 or IPv6)
  if (!ipaddr.isValid(clientIp)) {
    return false;
  }

  let isNeptune = false;
  neptuneRanges.forEach((ipRange) => {
    const cidr = new CIDR(ipRange);
    // Check if the client IP is within either of the Neptune ranges
    if (cidr.contains(clientIp)) {
      isNeptune = true;
    }
  });
  return isNeptune;
};
