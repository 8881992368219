import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Text,
  Stack,
  IconButton,
  Heading,
  Spinner,
  Wrap,
  Card,
  CardHeader,
  CardBody,
  StackDivider,
  Badge,
  Divider,
  Collapse,
  TagLabel,
  Tag,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import 'dayjs/locale/en-au';
import { useAuth0 } from '@auth0/auth0-react';
import { useLoaderData, useNavigate, useParams } from 'react-router';
import {
  CheckCircleIcon, CheckIcon, ChevronDownIcon, InfoIcon, ViewIcon, ViewOffIcon,
} from '@chakra-ui/icons';
import {
  FiCheckCircle,
  FiDisc,
  FiGlobe,
  FiHardDrive,
  FiInfo,
  FiStopCircle,
  FiWifi,
  FiWifiOff,
} from 'react-icons/fi';
import dayjs from 'dayjs';
import { SerializedError } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import customerObjectSlice, {
  fetchCustomerObject,
  fetchGroupsAsync,
  getHealthStatus,
  getIP,
  selectCustomerObject,
} from '../features/customer/customerObjectSlice';
import { Subscription } from '../models/customer';
import NavBar from './NavBar';
import NotAuthenticated from './NotAuthenticated';
import { ServiceCard } from './ServiceCards';
import { Loading } from './Loading';
import { DashboardTemplate } from './page_templates';
import { isNeptuneIp } from '../utils';
import { Help } from './Help';
import { Health, statusToBadge, statusToColour } from '../models/health';
import { useGetCustomerQuery, useGetEventsQuery } from '../services/eventsService';
import { setToken } from '../features/auth/auth';
import { RootState } from '../app/store';
import { EventType, eventTypeToString } from '../models/event';
import { DropoutsTags, HealthStatusAlert, NtdTags } from './HealthComponents';
import { HealthIndicatorTag } from './HealthComponents/HealthIndicatorTag';
import { CpeTags } from './HealthComponents/CpeTags';

dayjs.locale('en-au');

const IPv4Help = 'We have detected that your current IP address belongs to our network, which likely indicates that your internet connection is working properly. This means your device is successfully connected to our system, and everything appears to be functioning as expected.';

interface HealthReportElementProps {
  health: Health;
}

const HealthStatus = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showIPHelp, setShowIPHelp] = useState(false);
  const { subId } = useParams();
  const [subIP, setSetSubIP] = useState<undefined| string>(undefined);
  const [subIP6, setSetSubIP6] = useState<undefined| string>(undefined);
  const [subIP6Delegated, setSetSubIP6Delegated] = useState<undefined| string>(undefined);

  const token = useAppSelector((state: RootState) => state.auth.token);
  const ip = useAppSelector((state: RootState) => state.auth.ip);

  const [subscription, setSubscription] = useState<Subscription>(
    {} as Subscription,
  );

  const {
    data, error, isLoading: isEventsLoading, refetch,
  } = useGetEventsQuery(subId || '', {
    pollingInterval: 3000,
    skip: !token,
  });

  const {
    data: customer, error: customerError, isLoading: isCustomerLoading,
  } = useGetCustomerQuery('', {
    skip: !token,
  });

  useEffect(() => {
    dispatch(fetchCustomerObject(token));
  }, []);

  useEffect(() => {
    if (customer && customer?.subscriptions) {
      const s = customer?.subscriptions[subId || ''];
      if (s) {
        setSubscription(s);
        setSetSubIP(s.ip);
        setSetSubIP6(s.ip6);
        setSetSubIP6Delegated(s.ip6_delegated);
      }
    }
  }, [customer, isCustomerLoading]);

  const [showEvents, setShowEvents] = React.useState(false);

  const handleToggle = () => setShowEvents(!showEvents);

  let currentStatus: Health = {} as Health;
  try {
    currentStatus = JSON.parse([...data?.events || []].sort((a, b) => dayjs(b.timestamp).unix() - dayjs(a.timestamp).unix())
      .find((event) => event.event_type === EventType.HealthCheckComplete)?.payload || '{}');
  } catch (err) {
    console.log('err', err);
  }

  return (
    <DashboardTemplate>
      {showIPHelp && (
        <Help
          helpHeader="We detected your IP address successfully"
          helpText={IPv4Help}
          isOpen={showIPHelp}
          onClose={() => setShowIPHelp(false)}
        />
      )}

      {!!subIP
        && (
          <Box display="flex" flexDir="row" gap="4" flexWrap="wrap" textAlign="center" alignItems="center" justifyContent="center">

            <Tag colorScheme="green" mt="4">
              <Heading size="sm">
                {subscription?.nbn_details?.addressDetail?.formattedAddress}
              </Heading>
            </Tag>
          </Box>
        )}

      {error && (
        <Box
          marginX="5%"
          mb={2}
          mt={2}
        >
          <HealthStatusAlert
            status="error"
            title="Failed to fetch health status"
            description={'data' in error ? error.data as string : 'unknown error'}
          />
        </Box>
      )}

      <Box
        marginX="5%"
        mt={4}
        mb={2}
        flexDir="row"
        flexWrap="wrap"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
      >

        {data?.events && currentStatus
        && (
        <>
          <Tag size="lg" mb="2" mr="2" colorScheme="green" borderRadius="full">
            <FiGlobe />
            {' '}
            <TagLabel ml="1">
              IP:
              {' '}
              {subIP}
            </TagLabel>
          </Tag>
          <Tag size="lg" mb="2" mr="2" colorScheme="green" borderRadius="full">
            <FiGlobe />
            {' '}
            <TagLabel ml="1">
              IPv6:
              {' '}
              {subIP6}
            </TagLabel>
          </Tag>
          <Tag size="lg" mb="2" mr="2" colorScheme="green" borderRadius="full">
            <FiGlobe />
            {' '}
            <TagLabel ml="1">
              Delegated IPv6:
              {' '}
              {subIP6Delegated}
            </TagLabel>
          </Tag>
          <CpeTags cpe={currentStatus?.cpe} />
          <HealthIndicatorTag indicator={currentStatus?.health_indicator} name="performance" />
          <HealthIndicatorTag indicator={currentStatus?.health_indicator} name="connectivity" />
          <HealthIndicatorTag indicator={currentStatus?.health_indicator} name="stability" />
          <DropoutsTags health={currentStatus} />
          <NtdTags ntd={currentStatus?.ntd} />

        </>
        )}
      </Box>

      {!!data?.events && currentStatus
          && (
            <Box mt={4} mb={2} marginX="5%">
              <HealthStatusAlert
                status={
                  statusToBadge(currentStatus?.current_condition?.status as 'Red' | 'Green' | 'Amber')
                }
                description={currentStatus?.current_condition?.summary}
                title={currentStatus?.current_condition?.alertMessage}
              />
            </Box>
          )}

      <Box mb={2} marginX="5%">
        {isNeptuneIp(ip) ? (
          <HealthStatusAlert
            title="Your service is healthy"
            description="We have detected that you connected using the ip address to you.
                That indicates that your service most likely operates normally."
            status="success"
          />
        ) : (
          <HealthStatusAlert
            title="We detected that you have connected from outside of our network."
            description={`
               Your current IP address:
              ${ip}`}
            status="info"
          />
        )}
      </Box>
      <Divider mt={2} mb={4} />

      <Box marginTop="5" display="flex" flexDir="row" flexWrap="wrap" gap="6" alignItems="center" justifyContent="center">
        <Button bgColor="gray" isLoading={isCustomerLoading} onClick={() => navigate('/dashboard')}>
          Back to Dashboard
        </Button>

        <Button
          isLoading={isCustomerLoading || isEventsLoading}
          isDisabled={!!customerError}
          onClick={() => {
            dispatch(getHealthStatus({ token, subId: subId || '' }));
            setShowEvents(true);
          }}
        >
          Run Healthcheck
        </Button>

        <Button aria-label="foo" leftIcon={showEvents ? <ViewOffIcon /> : <ViewIcon />} color="green" onClick={handleToggle}>
          {showEvents ? 'Hide events' : 'Show events'}
        </Button>
      </Box>
      <Divider mt={2} mb={4} />

      <Collapse in={showEvents}>
        {isEventsLoading ? <Center><Spinner size="lg" /></Center>
          : [...data?.events || []].sort((a, b) => dayjs(b.timestamp).unix() - dayjs(a.timestamp).unix()).map((event) => (
            <Box mt={2} marginX="5%">
              <HealthStatusAlert
                status={
              eventTypeToString(event.event_type)[1]
            }
                description={eventTypeToString(event.event_type)[0]}
                title={dayjs(event.timestamp).format('DD MMMM YYYY, h:mm A')}
              />
            </Box>
          ))}
      </Collapse>

    </DashboardTemplate>
  );
};

export default HealthStatus;
