import React from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';
import { CloseIcon, AddIcon, HamburgerIcon } from '@chakra-ui/icons';
import Logo from '../images/neptune-logo-white.png';
import LogoutButton from './LogoutButton';

interface Props {
  children: React.ReactNode;
}

const Links: Array<any> = [];

const NavLink = (props: Props) => {
  const { children } = props;
  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded="md"
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      href="#"
    >
      {children}
    </Box>
  );
};

export interface NavBarProps {
  email: string;
  avatarLink: string;
}

const NavBar = ({ email, avatarLink }: NavBarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box bgColor="deepSpace.900" px={4}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display="none"
          onClick={isOpen ? onClose : onOpen}
        />
        <img
          alt="Neptune Internet logo"
          width="100px"
          style={{ marginLeft: '10px' }}
          src={Logo}
        />
        <HStack spacing={8} alignItems="center">
          <HStack as="nav" spacing={4} display={{ base: 'none', md: 'flex' }}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </HStack>
        </HStack>
        {email && email !== 'unknown' && (
          <Flex alignItems="center">
            <Menu>
              <MenuButton
                as={Button}
                rounded="full"
                variant="link"
                cursor="pointer"
                minW={0}
              >
                <Avatar size="md" src={avatarLink} />
              </MenuButton>
            </Menu>
            <LogoutButton />
          </Flex>
        )}
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as="nav" spacing={4}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default NavBar;
