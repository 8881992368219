import {
  Text, Link, Center, GridItem, Grid, Box,
} from '@chakra-ui/react';
import React from 'react';

export const Footer = () => (
  <Box py="10">
    <Center style={{ paddingTop: '0%' }}>
      <Text fontSize="xxs" color="white">
        © Neptune Internet 2024. All rights reserved.
        {' '}
      </Text>
    </Center>

    <Center style={{ paddingTop: '0%' }}>
      <Text fontSize="xxs" color="teal.500">
        <Link color="teal" href="https://status.neptune.net.au" isExternal>
          <b>Status Page</b>
        </Link>
      </Text>
    </Center>
  </Box>
);
