import {
  Box,
  Button, Flex, Heading, IconButton, Input, useClipboard,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { FiCopy } from 'react-icons/fi';
import { QRCodeGenerator } from './QrCodeGenerator';

export interface ReferralCodeCopyProps {
  code: string | undefined
}

export const ReferralCodeCopy: React.FC<ReferralCodeCopyProps> = ({ code }) => {
  const {
    onCopy, value, setValue, hasCopied,
  } = useClipboard('');

  useEffect(() => {
    setValue(`https://www.neptune.net.au/?referralCode=${code}` || '');
  }, [code]);

  if (code === undefined) {
    return null;
  }

  return (

    <>
      <Heading maxWidth="400" size="sm" mb="2" fontWeight="400">Refer a mate and both of you will get a month of the Internet on us. Click to copy.</Heading>
      <Box mb={2} flexDir="row" flexWrap="wrap" display="flex" gap="4" textAlign="center" justifyContent="center" alignContent="center">
        <Heading size="md">
          {hasCopied ? 'Copied!' : code}
          {' '}
        </Heading>
        <IconButton ml="2" size="xs" borderRadius="3" aria-label="referral code" icon={<FiCopy size="16" />} onClick={onCopy} />
        <QRCodeGenerator url={`https://www.neptune.net.au/?referralCode=${code}`} />
      </Box>
    </>
  );
};
