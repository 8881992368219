import React, { useState } from 'react';
import {
  Input, Button, Box, Flex, useToast, Heading, IconButton,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import { FiCode } from 'react-icons/fi';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

export interface QRCodeGeneratorProps {
  url: string
}
export const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({ url }) => {
  const [showQrCode, setShowQrCode] = useState(false);

  const handleGenerate = () => {
    setShowQrCode(!showQrCode);
  };

  return (
    <Box display="flex" flexDir="column">
      <Button size="xs" aria-label="generate qr code" leftIcon={showQrCode ? <ViewOffIcon /> : <FiCode />} colorScheme="blue" onClick={handleGenerate}>
        {showQrCode ? 'Hide QR' : 'Show QR'}

      </Button>
      {showQrCode && (
        <Box mt="6" p="4" border="1px solid #e2e8f0" borderRadius="md" flexDir="column">
          <QRCodeSVG value={url} size={256} />
        </Box>
      )}
    </Box>
  );
};
