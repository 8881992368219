import {
  CheckCircleIcon, InfoIcon, QuestionIcon, UnlockIcon, WarningIcon,
} from '@chakra-ui/icons';
import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { StatusColor } from '../../models/health';

export interface StatusToIconProps {
  status: StatusColor
}
export const StatusToIcon: React.FC<StatusToIconProps> = ({ status }) => {
  switch (status) {
    case 'Green':
      return <CheckCircleIcon />;
    case 'Amber':
      return <WarningIcon />;
    case 'Red':
      return <FiAlertTriangle />;
    default:
      return <QuestionIcon />;
  }
};
