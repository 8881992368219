import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Box, Spinner } from '@chakra-ui/react';

import React, { ComponentType } from 'react';
import twoClayHemispheres from '../images/two-clay-hemispheres.png';
import { Loading } from './Loading';

interface Props {
  component: ComponentType<object>;
}

export const AuthenticationGuard: React.FC<Props> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    /* eslint-disable-next-line */
    onRedirecting: () => <Loading />,
  });

  return <Component />;
};
