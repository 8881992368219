import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  ModalFooter,
} from '@chakra-ui/react';
import React from 'react';

export interface HelpProps {
  helpText: string;
  helpHeader: string;
  isOpen: boolean;
  onClose?: () => void;
}

export const Help: React.FC<HelpProps> = ({
  helpText,
  helpHeader,
  isOpen,
  onClose = () => null,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{helpHeader}</ModalHeader>
        <ModalCloseButton />

        <ModalBody pb={6}>
          <Text>{helpText}</Text>
        </ModalBody>

        <ModalFooter>
          <Button color="white" bgColor="deepSpace.700" onClick={onClose}>
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
