import {
  Box, Center, Grid, GridItem, Link, Text,
} from '@chakra-ui/react';
import React from 'react';
import LoginButton from './LoginButton';
import Logo from '../images/neptune-logo-white.png';
import { DashboardTemplate } from './page_templates';

const NotAuthenticated = () => (
  <DashboardTemplate>
    <Box display="flex" flexDir="column" position="relative" h="100px">
      <Center>
        <img
          alt="Neptune Internet logo"
          width="300px"
          style={{ paddingTop: '5%' }}
          src={Logo}
        />
      </Center>
      <Center style={{ paddingTop: '2%' }}>
        <Text fontSize="xl" color="white">
          Neptune subscribers portal
        </Text>
      </Center>
      <Center style={{ paddingTop: '2%' }}>
        <LoginButton />
      </Center>
    </Box>
  </DashboardTemplate>
);

export default NotAuthenticated;
