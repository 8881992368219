import {
  Alert,
  AlertIcon,
  Box, Button, Center, Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/neptune-logo-white.png';
import { DashboardTemplate } from './page_templates';

const NotWithNeptuneYet = () => (
  <DashboardTemplate>
    <Box
      display="flex"
      alignItems="center"
      justifyItems="center"
      textAlign="center"
      justifyContent="center"
      gap={10}
      mb={10}
      flexDir="row"
      flexWrap="wrap"
    >
      <Box>
        <img
          alt="Neptune Internet logo"
          width="300px"
          style={{ paddingTop: '5%' }}
          src={Logo}
        />
      </Box>
      <Alert fontSize="xl" color="black" colorScheme="red" textAlign="center" justifyContent="center">
        <AlertIcon />
        You do not have any services yet, please sign up first.
      </Alert>
      <Box>
        <Link to="https://neptune.net.au">
          <Button>Sign up for Neptune Internet</Button>
        </Link>

      </Box>
    </Box>
  </DashboardTemplate>
);

export default NotWithNeptuneYet;
