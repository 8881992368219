import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Grid,
  GridItem,
  Text,
  Link,
  Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import twoClayHemispheres from '../images/two-clay-hemispheres.png';
import NavBar from './NavBar';
import { Footer } from './Footer';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box bgColor="deepSpace.900">
      <NavBar email="unknown" avatarLink="#" />
      <Box
        px={['0.5rem', '1rem', '1.5rem', '2rem']}
        style={{
          backgroundImage: `url("${twoClayHemispheres}"), linear-gradient(to right bottom, #001A1A, #000F0F)`,
        }}
        sx={{
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
        color="white"
      >
        <Grid
          templateAreas={`"header"
                      "main"
                      "footer"`}
          margin="5px"
          h="100%"
          minHeight="100vh"
          flexGrow="row nowrap"
          gap="1"
        >
          <GridItem>
            <Box textAlign="center" alignItems="center" verticalAlign="center">
              <Alert
                borderRadius="50"
                marginTop="5%"
                textAlign="center"
                status="error"
                backgroundColor="pink"
              >
                <AlertIcon />
                <AlertTitle color="black">Page not found</AlertTitle>
                <AlertDescription color="black">
                  Looks like page you were looking for was not found or was
                  moved.
                </AlertDescription>
              </Alert>
              <Box marginTop="20">
                <Button onClick={() => navigate('/dashboard')}>
                  go to Dashboard
                </Button>
              </Box>
            </Box>
          </GridItem>
          <GridItem bgColor="deepSpace.900" area="footer">
            <Footer />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};
