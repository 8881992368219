import { CheckCircleIcon, InfoIcon, WarningIcon } from '@chakra-ui/icons';
import {
  AlertIcon, Spinner, Tag, TagLabel,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';
import {
  Health, HealthIndicator, StatusColor, statusToColour,
} from '../../models/health';
import { StatusToIcon } from './StatusToIcon';

export interface DropoutsTagsProps {
  health: Health
}

export const DropoutsTags: React.FC<DropoutsTagsProps> = ({ health }) => {
  if (!health?.dropouts) {
    return null;
  }

  const lastDropout = () => {
    if (!health?.dropouts?.last_dropout) {
      return 'no dropouts';
    }

    const current = dayjs();
    const last = dayjs(health?.dropouts?.last_dropout);
    if ((current.unix() - last.unix()) >= 90 * 86400) {
      return 'no dropouts';
    }

    return dayjs(health?.dropouts?.last_dropout).format('DD MMMM YYYY, h:mm A');
  };

  return (
    <>
      <Tag size="lg" mb="2" colorScheme={health?.dropouts?.total > 5 ? 'red' : 'green'} borderRadius="full" ml="4">
        <StatusToIcon status={health.dropouts.total > 5 ? 'Amber' : 'Green'} />
        <TagLabel ml="2" mr="1">
          Dropouts total:
          {' '}
          {health?.dropouts?.total || 'no dropouts'}
        </TagLabel>
      </Tag>
      <Tag size="lg" mb="2" colorScheme="blue" borderRadius="full" ml="4">
        <InfoIcon />

        <TagLabel ml="2">
          Last dropout:
          {' '}
          {lastDropout()}
        </TagLabel>
      </Tag>
    </>
  );
};
