import React, { useEffect, useState } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Heading,
  Spinner,
} from '@chakra-ui/react';
import { defer, Route, Routes } from 'react-router';
import NotAuthenticated from './components/NotAuthenticated';
import Dashboard from './components/Dashboard';
import { AuthenticationGuard } from './components/AuthGuard';
import { NotFound } from './components/NotFound';
import { Loading } from './components/Loading';
import HealthStatus from './components/HealthStatus';
import { Atmosphere } from './components/Atmosphere';
import { useAppDispatch } from './app/hooks';
import { setToken, setVerified } from './features/auth/auth';

const App = () => {
  const [verified, setEmailVerified] = useState(true);
  const {
    isAuthenticated, getIdTokenClaims, getAccessTokenSilently, isLoading,
  } = useAuth0();

  const dispatch = useAppDispatch();

  useEffect(() => {
    async function getClaims() {
      if (isAuthenticated) {
        const idToken = await getAccessTokenSilently();
        await dispatch(setToken(idToken));
      }
      const claims = await getIdTokenClaims();

      if (claims?.email_verified !== undefined) {
        setEmailVerified(!!claims?.email_verified);
        dispatch(setVerified(!!claims?.email_verified));
      }
    }
    getClaims();
  }, [isAuthenticated, dispatch, getAccessTokenSilently, getIdTokenClaims]);

  if (!verified) {
    return (
      <>
        <Alert status="error" backgroundColor="pink">
          <AlertIcon />
          <AlertTitle color="black">Please verify your email!</AlertTitle>
          <AlertDescription color="black">
            We have sent you an email verification link. Please check your spam
            or junk folder as well.
          </AlertDescription>
        </Alert>
        <NotAuthenticated />
      </>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<AuthenticationGuard component={Dashboard} />} />
      <Route
        path="/dashboard"
        element={<AuthenticationGuard component={Dashboard} />}
      />
      <Route
        path="/health/:subId"
        element={<AuthenticationGuard component={HealthStatus} />}
      />
      ;
      <Route
        path="/atmosphere/:subId"
        element={<AuthenticationGuard component={Atmosphere} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
