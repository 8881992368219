import { CheckCircleIcon, InfoIcon, WarningIcon } from '@chakra-ui/icons';
import {
  AlertIcon, Spinner, Tag, TagLabel,
} from '@chakra-ui/react';
import React from 'react';
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';
import {
  HealthIndicator, Ntd, StatusColor, statusToColour,
} from '../../models/health';
import { StatusToIcon } from './StatusToIcon';

export interface NtdTagsProps {
  ntd: Ntd
}

export const NtdTags: React.FC<NtdTagsProps> = ({ ntd }) => {
  if (!ntd) {
    return null;
  }

  return (
    <>
      <Tag
        size="lg"
        mb="2"
        colorScheme={
        statusToColour(ntd?.status) || 'green'
      }
        borderRadius="full"
        ml="4"
      >
        <StatusToIcon status={ntd?.status} />
        <TagLabel ml="2">{'NTD' || 'loading...'}</TagLabel>
      </Tag>
      {ntd?.port_state && (
      <Tag
        size="lg"
        mb="2"
        colorScheme={
          ntd?.port_state === 'Up' ? 'green' : 'red'
        }
        borderRadius="full"
        ml="4"
      >
        <StatusToIcon status="Grey" />
        <TagLabel ml="2">{`Port ${ntd.port} is ${ntd.port_state}` || 'loading...'}</TagLabel>
      </Tag>
      )}
    </>
  );
};
